<template>
    <Table model="grades" title="Grade" subTitle="" :addons="[
      'filter',
      'sort',
      'search',
      'columns',
      'refresh',
      'mailme',
      'mail',
    ]" :exportOptions="['pdf', 'excel', 'csv', 'json']"
      :extendedMenus='["edit", "delete", "mail", "view", "clone", "freeze"]' @addData="addData" @rowAction="rowActions"
      @filter="true" @columnSelection="true" @saveLayout="true" :pagination="true" :infiniteScroll="true"
      :defaultLayout="null" :data="data" :defaultColumns="column" :defaultFilter="computeDefaultFilter()" @filterData="applyFilter" 
      :miniTab="miniTab" viewComponent="Settings/Grade/tab.vue" @reload="getTableData" />
    <!-- </v-main>
    </v-layout> -->
  </template>
  <script>
  import GradeService from "@/api/service/settings/GradeService";
  import Table from "@/components/Table.vue";
  export default {
    components: {
      Table,
      // ListTable, SubSideMenu,TableTitle,
    },
    data() {
      return {
        data: [],
        column: [],
        filterId: null,
        viewType: null,
        miniTab: {
          "title": "code",
          "subTitle": "name",
          "message": {
            "type": "column",
            "datatype": "date",
            "format": "MM/DD/YYYY",
            "label": "Created",
            "column": "created"
          }
          },
          editDataId: null
        };
    },
    created() {
      // this.$store.commit('setSideMenuGroup',{"activity_group_code":"organisation"})
      this.getTableData();
    },
    methods: {
      async getTableData(obj) {
        this.data = await GradeService.findAll(obj?obj:{});  
        this.column = [
          { label: "Code", column_name: "code", column_required: 1 },
          { label: "Name", column_name: "name", column_required: 0 },
          { label: "Finance Code", column_name: "finance_code", column_required: 1 },
          { label: "Legal Name", column_name: "legal_name", column_required: 0 },
          { label: "Website", column_name: "website", column_required: 1 },
        ];
  
      },
      applyFilter(data){
        this.getTableData(data)
      },
      changeFilter(tab) {
        console.log(tab);
      },
      // addData() {
      //   this.$router.push("/plm");
      // },
      computeDefaultFilter() {
        return this.filterId
          ? { filterId: this.filterId }
          : { filter: { active: 1, deleted: 0 } };
      },
      addData() {
        alert('add')
      },
      exportData(type) {
        alert(type)
      },
      rowActions($event) {
        this.viewType = $event.type;
        this.editDataId = $event.data.id;
  
        console.log(this.viewType, this.editDataId, 'kkkkkkkkkaaa')
      },
      removeColumn(index) {
        if (index >= 0 && index < this.column.length) {
          this.column.splice(index, 1);
        }
      },
      copyData(i) {
        if (navigator.clipboard) {
          navigator.clipboard
            .writeText(this.data[i].orderNumber)
            .then(() => {
              console.log("Text successfully copied to clipboard");
            })
            .catch((err) => {
              console.error("Unable to copy text: ", err);
            });
        }
      },
    },
  };
  </script>
    
  <style scoped>
  .v-navigation-drawer {
    border: none;
    border-radius: 2px;
    background: unset;
    margin-top: 0px !important;
  }
  </style>