<template>
    <TableTitle v-if="!hideTitle" :title="title" :hideAdd="hideAdd" :options="exportOptions" @exportData="exportData" :hideExport="hideExport"
        @addData="handleData" />


    <v-row v-if="showList" no-gutters>
        <v-col cols="2" v-if="showMenu">
            <SubSideMenu :listData='data' :display="miniTab" @rowClicked="rowClicked" :id="editId" />
        </v-col>
        <v-col :cols="showMenu ? '10' : '12'" :class="showMenu ? '' : 'ml-2'">
            <component v-if="selectedItem && selectedItem.id || !showMenu" :is="getComponent" @closeDialog="closeDialog"
                :id="editId" :type="type" :showReadonly="showReadonly" :componentData="selectedItem" />
        </v-col>
    </v-row>
    <div v-else :class="hideTitle ? '' : 'pl-3 pt-3'">
        <div style="background-color: #fff; padding: 0px 10px;overflow: auto;border-radius: 5px;">
            <TableOptions v-if="!noTableOptions && !noDataFound" :searchReq="featureOptions ? featureOptions.includes('search') : ''"
                @filterData="$emit('filterData', $event)" :model="model"
                :sortReq="featureOptions ? featureOptions.includes('sort') : ''"
                :filterReq="featureOptions ? featureOptions.includes('filter') : ''"
                :columnReq="featureOptions ? featureOptions.includes('columns') : ''" @searchTable="searchTable"
                :columns="allColmuns" :title="title" :itemsPerPage="itemsPerPage" :showContext="pagination"
                :allData="data ? data.length : 0" @allSelected="allSelected" @editColumns="sendColumns" />

            <div style="overflow: auto;" >
                <table class="custom-table" v-if="!noDataFound">
                    <thead class="custom-thead">
                        <tr>
                            <th class="text-left pl-2" v-if="checkBox">
                                <input type="checkbox" v-model="allData" class="styled-checkbox" id="styled-checkbox" />
                                <label for="styled-checkbox" class="styled-checkbox-label"></label>
                            </th>
                            <th :class="columnFreeze === i ? 'freezeToTop' : ''" v-for="(defaultColumns, i) in columns"
                                :key="i" class="text-left">
                                <span style="display: flex; align-items: center; gap: 4px">
                                    <span @click="freezeColumn(i)" style="text-wrap: nowrap;">
                                        {{ defaultColumns.label }}
                                        <v-tooltip activator="parent" location="top">click to freeze column in
                                            start</v-tooltip>
                                    </span>
                                    <i @click="sortColumn(defaultColumns.column_name)" v-if="!noSort"
                                        class="tibura-sort-direction pt-1 ml-1" style="color: #0f62fe; cursor: pointer"></i>
                                </span>
                            </th>

                        </tr>
                    </thead>
                    <tbody class="custom-body" :class="title == 'hts' ? 'hts' : ''">
                        <template v-for="(item, index) in showData" :key="index">
                            <tr :class="rowFreeze === index ? 'freezeToTop' : ''" class="tableRow"
                                @click="tableRowClicked(item)">
                                <td class="text-left pl-2" v-if="checkBox">
                                    <input type="checkbox" v-model="item.checked" class="styled-checkbox"
                                        :id="'styled-checkbox-' + item.id" />
                                    <label :for="'styled-checkbox-' + item.id" class="styled-checkbox-label"></label>
                                </td>
                                <td   v-for="defaultColumns in columns" :key="defaultColumns.column_name">
                                    <p style="text-wrap: nowrap;" v-if="defaultColumns.source_type == 'model'"
                                        class="line-clamp" @click="show = !show"
                                        :class="getClasses(item[defaultColumns.column_name], defaultColumns)">
                                        {{ item[defaultColumns.source][defaultColumns.text] }}
                                        <v-tooltip width="20rem" content-class="custom-tooltip"
                                            style="opacity: .8;font-size:10px !important"
                                            v-if="getClasses(item[defaultColumns.column_name], defaultColumns) && getClasses(item[defaultColumns.column_name], defaultColumns).length > 45"
                                            activator="parent" location="bottom">{{
                                                getClasses(item[defaultColumns.column_name], defaultColumns) }}</v-tooltip>
                                    </p>
                                    <p style="text-wrap: nowrap;" v-else-if="defaultColumns.column_name == 'created_by'"
                                        class="line-clamp" @click="show = !show"
                                        :class="getClasses(item[defaultColumns.column_name], defaultColumns)">
                                        {{ item.createdUser && item.createdUser.username }}
                                        <v-tooltip width="20rem" content-class="custom-tooltip"
                                            style="opacity: .8;font-size:10px !important"
                                            v-if="getClasses(item[defaultColumns.column_name], defaultColumns) && getClasses(item[defaultColumns.column_name], defaultColumns).length > 45"
                                            activator="parent" location="bottom">{{
                                                getClasses(item[defaultColumns.column_name], defaultColumns) }}</v-tooltip>
                                    </p>
                                    <p style="text-wrap: nowrap;" v-else-if="defaultColumns.column_name == 'modified_by'"
                                        class="line-clamp" @click="show = !show"
                                        :class="getClasses(item[defaultColumns.column_name], defaultColumns)">
                                        {{ item.modifiedUser && item.modifiedUser.username }}
                                        <v-tooltip width="20rem" content-class="custom-tooltip"
                                            style="opacity: .8;font-size:10px !important"
                                            v-if="getClasses(item[defaultColumns.column_name], defaultColumns) && getClasses(item[defaultColumns.column_name], defaultColumns).length > 45"
                                            activator="parent" location="bottom">{{
                                                getClasses(item[defaultColumns.column_name], defaultColumns) }}</v-tooltip>
                                    </p>
                                    <p v-else style="text-wrap: nowrap;" class="line-clamp" @click="show = !show"
                                        :class="getClasses(item[defaultColumns.column_name], defaultColumns)">
                                        {{ truncatedData(getClasses(item[defaultColumns.column_name], defaultColumns)) }}
                                        <v-tooltip width="20rem" content-class="custom-tooltip"
                                            style="opacity: .8;font-size:10px !important"
                                            v-if="getClasses(item[defaultColumns.column_name], defaultColumns) && getClasses(item[defaultColumns.column_name], defaultColumns).length > 45"
                                            activator="parent" location="bottom">{{
                                                getClasses(item[defaultColumns.column_name], defaultColumns) }}</v-tooltip>
                                    </p>


                                </td>
                                <td class="fixed-menu-cell">
                                    <v-menu v-if="extendedMenus && extendedMenus.length > 0">
                                    <template v-slot:activator="{ props }">
                                        <v-icon v-bind="props" size="large" :color="'#0F62FE'">mdi-dots-vertical</v-icon>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                        v-for="(type, index) in extendedMenus"
                                        :key="index"
                                        :value="index"
                                        @click="rowActions(item, index, type)"
                                        >
                                        <v-list-item-title>{{ capitalizeFirstLetter(type) }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                    </v-menu>
                                </td>

                                
                            </tr>
                        </template>
                    </tbody>
                </table>
                <div style="display: flex;justify-content: center;align-items: center;flex-direction: column;" class="pa-6" v-if="noDataFound">
                    <img src="../../public/no-record.png" style="width: 17%">
                    <p style="font-size: 17px;font-weight: 500;">Data  Not  Available</p>
                    <p style="font-size: 15px;">Please check if you have permission or try adding new records!!</p>
                </div>
            </div>
        </div>
        <div v-if="pagination" style="display: flex; justify-content: space-between" class="my-3">
            <div class="pagination-menu">
                <div>
                    <span style="color: #0f62fe; font-size: 12px; font-weight: 600">{{
                        itemsPerPage
                    }}</span>&nbsp;
                    <span style="color: #121619; font-size: 12px; font-weight: 400">per page</span>
                    <v-icon :color="'#0F62FE'">mdi-menu-down</v-icon>
                </div>
                <v-menu activator="parent">
                    <v-list>
                        <v-list-item v-for="(item, index) in perPageOptions" :key="index" :value="index"
                            @click="changeItemPerPage(item)">
                            <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>

            <div class="pagination-container">
                <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :color="'#0F62FE'" v-model="currentPage"
                    :length="totalPages" @input="changePage" :total-visible="5" class="custom-pagination"></v-pagination>
            </div>
        </div>
    </div>
</template>
  
<script>
import { defineAsyncComponent } from "vue";
import PoDatils from './podetails/index.vue'
import SubSideMenu from "./SubSideMenu.vue";
// import CheckBox from './CheckBox.vue';
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import TableTitle from "./TableTitle.vue";
import TableOptions from "./TableOptions.vue";
import exportFromJSON from "export-from-json";
import * as XLSX from 'xlsx';
import ListModelDetailService from "@/api/service/technical/ListModelDetailService"
import moment from 'moment';
//import Tab from '@/modules/Organization/companies/tab.vue';
import CommonService from '@/api/service/common/CommonService';
export default {
    components: { TableTitle, TableOptions, SubSideMenu, PoDatils },
    props: [
        "data",
        "checkBox",
        "defaultColumns",
        "title",
        "pagination",
        "exportOptions",
        "addons",
        "extendedMenus",
        "model",
        "fileName",
        "hideAdd",
        "miniTab",
        "viewComponent",
        "noTableOptions",
        "noTableTitle",
        "noSort",
        'hideExport'
    ],
    data() {
        return {
            hideTitle: this.noTableTitle,
            showReadonly: true,
            selectedItem: null,
            type: null,
            editId: null,
            finalColumn: null,
            columnFreeze: null,
            rowFreeze: null,
            featureOptions: this.addons,
            sortBy: null,
            showList: false,
            sortDesc: false,
            allData: false,
            allColmuns: null,
            columns: null,
            showData: null,
            itemsPerPage: 10,
            perPageOptions: [5, 10, 15, 20, 30, 50],
            currentPage: 1,
            searchQuery: "",
            showMenu: true,
            noDataFound:false
        };
    },
    mounted() {
        this.displayedData();
    },
    created() {
        this.getColumns()
    },
    computed: {
        getComponent() {
            return defineAsyncComponent(() => import(`../modules/${this.viewComponent}`))
        },

        totalPages() {
            return this.data ? Math.ceil(this.data.length / this.itemsPerPage) : 0;
        },
    },
    methods: {
        moment,
        truncatedData(data) {
            if (data && data.length > 45) {
                return data.substring(0, 42) + '...';
            } else {
                return data;
            }
        },
        closeDialog() {
            this.showList = false;
            this.showMenu = true
            this.hideTitle = false
            if(this.noDataFound==true){
                this.noDataFound=false
            }
            this.$emit('reload')
        },
        getClasses(column, columnData) {
            let classes = CommonService.getClass(column, columnData)
            return classes
        },
        handleData() {
            this.hideTitle = true
            this.showList = true;
            this.type = 'add';
            this.showReadonly = false;
            if (this.type === 'add') {
                this.showMenu = false;
            }
        },


        async allSelected(allSelect) {
            if (this.allColmuns) {
                const newValue = allSelect ? 1 : 0;

                this.allColmuns.forEach(column => {
                    column.column_required = newValue;
                });

                await this.getColumns(this.allColmuns);
            }
        },

        async sendColumns(data) {
            const indexToRemove = this.allColmuns.findIndex(
                (item) => item.column_name === data.column_name
            );
            if (this.allColmuns[indexToRemove].column_required === 1) {
                this.allColmuns[indexToRemove].column_required = 0
            } else {
                this.allColmuns[indexToRemove].column_required = 1
            }
            this.finalColumn = this.allColmuns;
            await this.getColumns(this.finalColumn);
        },

        async getColumns(data) {
            if (data) {
                this.columns = data.filter(column => column.column_required);
            } else {
                if (!this.model) {
                    this.allColmuns = this.defaultColumns
                    this.columns = this.allColmuns ? this.allColmuns.filter(column => column.column_required) : '';
                    console.log(this.defaultColumns, 'newcolums');
                } else {
                    let res = await ListModelDetailService.getTableColumn({ tableName: this.model });
                    res = res.data;
                    this.allColmuns = res
                    this.columns = this.allColmuns ? this.allColmuns.filter(column => column.column_required) : '';
                    console.log(this.columns, 'newcolums');
                }
            }
        },

        tableRowClicked(item) {
            this.hideTitle = true
            this.showList = true;
            this.type = 'view';
            this.selectedItem = item;
            this.editId = item.id;
            this.showReadonly = true;

            if (this.hideAdd) {
                this.hideTitle = false
                this.showList = false;
            }
            // this.$store.commit('setSideMenuGroup',false)
        },
        rowClicked(item) {
            this.editId = item.id;
            this.selectedItem = item
            this.type = 'view'
            this.showReadonly = true
            this.$emit('rowClicked', item)
        },

        sortColumn(name) {
            if (this.sortBy === name) {
                this.sortDesc = !this.sortDesc;
            } else {
                this.sortBy = name;
                this.sortDesc = false;
            }
            this.showData = this.showData.sort((a, b) => {
                const valueA = a[name];
                const valueB = b[name];

                console.log(valueA, valueB, "ku");

                if (valueA === undefined && valueB === undefined) {
                    return 0;
                } else if (valueA === undefined) {
                    return this.sortDesc ? 1 : -1;
                } else if (valueB === undefined) {
                    return this.sortDesc ? -1 : 1;
                }
                if (typeof valueA === "string" && typeof valueB === "string") {
                    return this.sortDesc
                        ? valueB.localeCompare(valueA, undefined, { numeric: true })
                        : valueA.localeCompare(valueB, undefined, { numeric: true });
                } else {
                    return this.sortDesc ? valueB - valueA : valueA - valueB;
                }
            });
        },

        async exportData($event) {
            let fileName = this.fileName ? this.fileName : `${this.title}`;
            const table = document.querySelector('table');
            // let data = await this.formattedData()
            if ($event.toLowerCase() == 'excel') {
                this.exportExcel(table, fileName)
            }
            if ($event.toLowerCase() == 'csv') {
                this.exportAsCSV(table, fileName)
            }
            else if ($event.toLowerCase() == 'json') {
                const exportType = exportFromJSON.types.json;
                const formattedData = this.formatDataForExport();
                exportFromJSON({ data: formattedData, fileName, exportType });
            }
            else {
                this.exportAsPDF(this.showData, fileName)
            }
        },

        exportAsCSV(table, name) {
            const pdf = new jsPDF();
            pdf.autoTable({ html: table });
            const dataUri = pdf.output('datauristring');
            const link = document.createElement('a');
            link.href = dataUri;
            link.download = `${name}.csv`;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },

        setPDFHeader(data) {
            return [
                Object.keys(data[data.length - 1]).map(
                    (item) =>
                        `${item.charAt(0).toUpperCase()}${item.substr(1, item.length)}`
                ),
            ];
        },
        calculateFontSize(count) {
            return count ? 50 / count : count;
        },
        setPDFBody(data) {
            return data.map((item) => {
                const keys = Object.keys(item);
                const values = [];
                keys.forEach((key) => {
                    if (typeof item[key] === "object" && item[key] !== null) {
                        values.push(
                            item[key].name
                                ? item[key].name
                                : item[key].code
                                    ? item[key].code
                                    : item[key].name
                        );
                    } else {
                        values.push(item[key]);
                    }
                });
                return values;
            });
        },

        exportAsPDF(data, fileName) {
            const headers = this.setPDFHeader(data);
            const fontSize = this.calculateFontSize(headers[0].length);
            const body = this.setPDFBody(data);
            const doc = new jsPDF();
            autoTable(doc, { html: "#dt2" });
            doc["autoTable"]({
                styles: {
                    cellPadding: 0.5,
                    fontSize: fontSize,
                },
                headStyles: {
                    fillColor: "#3f51b5",
                    textColor: "#fff",
                    halign: "center",
                },
                bodyStyles: {
                    halign: "center",
                },
                margin: {
                    left: 5,
                    right: 5,
                },
                tableLineWidth: 1,
                head: headers,
                body: body,
            });
            doc.save(fileName);
        },


        formatDataForExport() {
            return this.showData.map((item) => {
                const formattedItem = { ...item };
                Object.keys(formattedItem).forEach((key) => {
                    if (formattedItem[key] === undefined) {
                        formattedItem[key] = '';
                    }
                });
                return formattedItem;
            });
        },

        exportExcel(table, name) {
            const ws = XLSX.utils.table_to_sheet(table);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, `${name}.xlsx`);
        },

        freezeColumn(i) {
            this.columnFreeze = i
            let removedItem = this.columns.splice(this.columnFreeze, 1);
            this.columns.unshift(removedItem[0]);
            this.displayedData()
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        rowActions($event, index, type) {
            if (type === 'freeze') {
                this.rowFreeze = index
                let removedItem = this.showData.splice(this.rowFreeze, 1);
                this.showData.unshift(removedItem[0]);
            }
            let data = {
                data: $event,
                type: type
            }
            if (type === 'edit' || type === 'view') {
                this.hideTitle = true
                this.$store.commit('setSideMenuGroup', false)
                this.showList = true
                this.type = type;
                this.editId = $event.id
                this.selectedItem = $event

                if (type === 'edit') {
                    this.showReadonly = false
                } else {
                    this.showReadonly = true
                }
            }
            this.$emit('rowAction', data)
        },

        toggleAllCheckboxes() {
            this.showData.forEach((item) => {
                item.checked = this.allData;
            });
        },
        searchTable(query) {
            this.searchQuery = query;
            this.displayedData();
        },
        generateKey(item) {
            return item.code;
        },
        changeItemPerPage(item) {
            this.itemsPerPage = item;
            this.displayedData();
        },
        changePage(page) {
            this.currentPage = page;
            this.displayedData();
        },
        editItem(index) {
            this.$emit("editData", index);
        },
        copyItem(index) {
            this.$emit("copyData", index);
        },
        deleteItem(index) {
            this.$emit("deleteData", index);
        },
        isActive(index) {
            return index % 2 === 0;
        },
        getStatus(index) {
            return this.isActive(index) ? "Active" : "In-Active";
        },

        displayedData() {
            let filteredData = this.data
            if (this.searchQuery) {
                const lowerCaseQuery = this.searchQuery.toLowerCase();
                filteredData = filteredData.filter((item) =>
                    Object.values(item).some(
                        (value) =>
                            value && value.toString().toLowerCase().includes(lowerCaseQuery)
                    )
                );
            }
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            if (this.pagination) {
                this.showData = filteredData && filteredData.length > 0 ? filteredData.slice(startIndex, endIndex)  : [];
            } else {
                this.showData = filteredData;
            }
        },
    },

    watch: {
        currentPage() {
            this.displayedData();
        },
        data() {
            this.displayedData();
            if(this.data && this.data.length==0){
                this.noDataFound=true
            }
        },
    },
};
</script>
<style scoped>
>>>.v-tooltip {
    opacity: 0.8;
    font-size: 0.575rem;
}
.fixed-menu-cell {
    position: sticky;
    right: 0;
    z-index: 999; /* Adjust z-index as needed */
    background-color: white; /* Adjust background color as needed */
}

.optionText {
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 500;
    color: #6d7377;
}

.custom-table {
    width: 100%;
    background-color: #fff;
    border-collapse: collapse;
}

.custom-thead tr th {
    height: 5vh !important;
    background-color: #edf5ff !important;
    text-align: left !important;
}

.custom-thead tr th {
    color: #6d7377 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
}


.tableRow .actions {
    background-color: #fff;
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    width: 26px;
    
}



.tableRow:hover .actions {
    visibility: visible;
}

th,
td {
    padding-left: 10px;
    padding-right: 10px;
}

.custom-body tr td {
    height: 6vh;
    font-size: 12px;
    font-weight: 400;
    color: #121619;
    text-align: left;
}

.line-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.custom-body tr:not(:last-child) {
    border-bottom: 1px dashed #e1e2e5;
}

.custom-body tr:hover {
    background-color: #edf5ff;
    border-top: 0px;
}


.chip {
    padding: 5px 10px;
    width: fit-content;
    border-radius: 4px;
    font-size: 14;
    font-weight: 400;
}

.Active {
    padding: 3px 8px;
    background-color: #CFFCD1;
    color: #198038;
    border-radius: 4px;
    width: fit-content;
    height: fit-content !important;
}

.Inactive {
    padding: 3px 8px;
    background-color: #CFFCD1;
    color: #198038;
    border-radius: 4px;
    width: fit-content;
}



.pagination-container {
    background-color: #fff;
    border-radius: 8px;
    font-size: 12px !important;
    height: fit-content;
    font-weight: 500;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
}

.pagination-menu {
    background-color: #fff;
    height: fit-content;
    border-radius: 8px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
    display: flex;
    align-items: center;
    padding: 2px 10px;
}

>>>.v-pagination .v-btn {
    border-radius: 4px;
    height: fit-content;
    padding: 2px;
    font-size: 12px;
}

.styled-checkbox {
    display: none;
}

.styled-checkbox-label {
    position: relative;
    cursor: pointer;
    padding-left: 2rem;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1.2rem;
        height: 1.2rem;
        border: 2px solid #6d7377;
        background-color: #fff;
        border-radius: 3px;
    }

    &::after {
        content: "";
        position: absolute;
        left: 40%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 0.6rem;
        height: 0.6rem;
        border-bottom: 2px solid #0f62fe;
        border-right: 2px solid #0f62fe;
        transform-origin: 0% 50%;
        opacity: 0;
    }

    input:checked+&::before {
        border: 2px solid #0f62fe;
        background-color: #0f62fe;
    }

    input:checked+&::after {
        opacity: 1;
    }
}
</style>