const baseUrl = {
    "auth":"https://hr-staging.tiburaglobal.com:3033",
    "apps":"https://79a4z7n2g5.execute-api.ap-south-1.amazonaws.com/stage"
}

const appDetails = {
    "appName": "Identti Front End",
    "apiKey": "dms",
    "environment":"dev",
    "secrect": "secrect",
    "appId": "b0dc92bf-3710-4e11-a87d-4d72585cd281"
}

export default {baseUrl,appDetails}
