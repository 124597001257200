<template>
  <div>
    <!-- <v-layout> -->
    <v-navigation-drawer
      v-if="menuItems && menuItems.length > 0"
      fixed
      location="left"
      :key="navDrawerPinned"
      :rail="rail"
      mobile-breakpoint="0"
      style="height: 100vh; z-index: 0; top: -55px; transform: translateX(0%);"
      class="side-menu"
      permanent
      
    >
    <v-tooltip :text="toggleTip">
      <template v-slot:activator="{ props }">
        <button style="right: -12px;top: 17px;color: white;position: absolute;z-index:9999" v-bind="props">
            <v-icon @click="toggleRail" :color="white" v-if="rail">mdi-hexagon-outline</v-icon>
            <v-icon @click="toggleRail" :color="white" v-else>mdi-arrow-left-drop-circle-outline</v-icon>
        </button>
      </template>
    </v-tooltip>
      <v-list-item  >
      <div v-if="rail" :prepend-avatar="require('../../assets/harmonyico.png')" >
      </div>
        <img
          :src="require('../../assets/harmony.svg')"
          style="
            cursor: pointer;
            height: 32px;
            margin-top: 10px;
            padding-left: 0px;
          "
          @click="$router.push('/')"       
        />
        <!-- <template v-slot:append> -->

        <!-- </template> -->
      
      </v-list-item>
      <v-list densit="compact">
        <!-- <v-list-title class="main-list-Title pl-8 py-8">{{ listTitle }}</v-list-title> -->

        <v-divider class="pa-0 mb-1 dividerr" :color="''"></v-divider>

        <!-- <v-btn
        icon="mdi-chevron-left"
          variant="text"
          @click.stop="rail = !rail"
          style="background: white;position:relative;left:81%" 
          ></v-btn> -->

        <template v-for="menu in menuItems" v-bind:key="menu.title">
          <v-list-subheader style="color: #ffffff; font-weight: 600">
            <v-icon>{{ menu.icon ? menu.icon : null }}</v-icon>
            <span class="ml-2" v-if="!rail">{{ menu.activity_group_name ? menu.activity_group_name : null }}</span>
          </v-list-subheader>
          <template
            v-for="submenu in menu.menu"
            v-bind:key="submenu.activity_code"
          >
          <div v-if="!rail">
                        <v-list-subheader
              class="subTitjjle"
              :class="
                rail
                  ? ''
                  : 'ml-3' && selected === submenu.activity_name
                  ? 'active'
                  : ''
              "
              @click="menuClick(submenu)"
            >
              <v-icon>{{ submenu.icon ? submenu.icon : null }}</v-icon>&nbsp;
              {{ submenu.activity_name ? submenu.activity_name : null }}
            </v-list-subheader>
          </div>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>
    <!-- </v-layout> -->
  </div>
</template>
<script>
import AppService from "@/api/service/AppService";

export default {
  props: ["query", "filteredMenu"],
  components: {},
  data() {
    return {
      drawer: true,
      rail: false,
      dialog: false,
      selected: null,
      groupId: null,
      listTitle: null,
      showMenu: false,
      show: false,
      toggleTip:'Minimize',
      userName: localStorage.getItem("_CUSER_"),
      menuItems: [
        {
          activity_group_code: "hrms",
          activity_group_name: "HRMS",
          icon: "mdi-account-multiple",
          menu: [
            {
              activity_name: "Employee",
              activity_code: "employee",
              icon: "mdi-account",
              route: "/hrms/employee",
              id: 5,
            },
            {
              activity_name: "Self Service",
              activity_code: "selfservice",
              icon: "mdi-toolbox-outline",
              route: "/hrms/selfService",
              id: 5,
            },
            {
              activity_name: "Leave",
              activity_code: "Leave",
              id: 5,
            },
            {
              activity_name: "Attendance",
              activity_code: "attendance",
              id: 5,
            },
            {
              activity_name: "Performance",
              activity_code: "performance",
              id: 5,
            },
            {
              activity_name: "Travel",
              activity_code: "travel",
              id: 5,
            },
            {
              activity_name: "Expenses",
              activity_code: "expense",
              id: 5,
            },
            {
              activity_name: "Announcements",
              activity_code: "announcements",
              id: 5,
            },
          ],
        },
        {
          activity_group_code: "recruit",
          activity_group_name: "E-Recruit",
          icon: "mdi-account-search",
          menu: [
            {
              activity_name: "Budget",
              activity_code: "budget",
              id: 5,
            },
            {
              activity_name: "Staffing Request",
              activity_code: "request",
              id: 5,
            },
            {
              activity_name: "Resume",
              activity_code: "resume",
              id: 5,
            },
            {
              activity_name: "Job Board",
              activity_code: "board",
              id: 5,
            },
            {
              activity_name: "Offer",
              activity_code: "offers",
              id: 5,
            },
            {
              activity_name: "Onboarding",
              activity_code: "onboarding",
              id: 5,
            },
          ],
        },
        {
          activity_group_code: "settings",
          activity_group_name: "HR-Settings",
          icon: "mdi-cog",
          menu: [
            {
              activity_name: "Grade",
              activity_code: "Grade",
              id: 5,
              route: "/admin/settings/grade",
            },
            {
              activity_name: "Job Role",
              activity_code: "jobrole",
              id: 5,
              route: "/admin/settings/jobRole",
            },
            {
              activity_name: "Business Title",
              activity_code: "business-title",
              id: 5,
              route: "/admin/settings/BusinessTitle",
            },
            {
              activity_name: "Benefit",
              activity_code: "Benefit",
              id: 5,
              route: "/admin/settings/benefits",
            },
            {
              activity_name: " Benefit Template",
              activity_code: " BenefitTemplate",
              id: 5,
              route: "/admin/settings/BenefitTemplates",
            },
            {
              activity_name: " Leave",
              activity_code: " leave",
              id: 5,
              route: "/admin/settings/leave",
            },
            {
              activity_name: " Leave Template",
              activity_code: " leaveTemplate",
              id: 5,
              route: "/admin/settings/leaveTemplate",
            },
            {
              activity_name: " Holidays",
              activity_code: " holidays",
              id: 5,
              route: "/admin/settings/holidays",
            },
            {
              activity_name: " Holiday Template",
              activity_code: " holidaystemplates",
              id: 5,
              route: "/admin/settings/holidaysTemplates",
            },
            {
              activity_name: "Pay Component",
              activity_code: "component",
              id: 5,
              route: "/admin/settings/paycomponent",
            },
            {
              activity_name: "Pay Component Template",
              activity_code: "component",
              id: 5,
              route: "/admin/settings/PayComponentsTemplate",
            },
            {
              activity_name: "Work Timings",
              activity_code: "worktimings",
              id: 5,
              route: "/admin/settings/WorkTimings",
            },
          ],
        },
        {
          activity_group_code: "Organisation",
          activity_group_name: "Organisation",
          icon: "mdi-account-group",
          menu: [
            {
              activity_name: "Company",
              activity_code: "company",
              route: "/admin/organisation/company",
              id: 5,
            },
            {
              activity_name: "Division",
              activity_code: "division",
              route: "/admin/organisation/division",
              id: 5,
            },
            {
              activity_name: "Department",
              activity_code: "department",
              route: "/admin/organisation/department",
              id: 5,
            },
            {
              activity_name: "Sub-Department",
              activity_code: "Sub-Department",
              route: "/admin/organisation/subdepartment",
              id: 5,
            },
            {
              activity_name: "Branches",
              activity_code: "branches",
              route: "/admin/organisation/branches",
              id: 5,
            },
            {
              activity_name: "SBU",
              activity_code: "SBU",
              route: "/admin/organisation/sbu",
              id: 5,
            },
            {
                "activity_name":"LOB",
                "activity_code":"LOB",
                "route":"/admin/organisation/lob",
                "id":5
            }
          ],
        },
        {
          activity_group_code: "Identity",
          activity_group_name: "HR-Identity",
          menu: [
            {
              activity_name: "Role",
              activity_code: "role",
              route: "/admin/identity/role",
              id: 5,
            },
            {
              activity_name: "Security Group",
              activity_code: "security-group",
              route: "/admin/identity/securitygroup",
              id: 5,
            },
            {
              activity_name: "Permissions",
              activity_code: "permission",
              route: "/admin/identity/permission-config",
              id: 5,
            },
            {
              activity_name: "Assign Permissions",
              activity_code: "assign-permission",
              route: "/admin/identity/rolepermission",
              id: 5,
            },
            {
              activity_name: "User",
              activity_code: "user",
              route: "/admin/identity/user",
              id: 5,
            },
          ],
        },
        {
          activity_group_code: "jurisdiction",
          activity_group_name: "HR-Jurisdiction",
          menu: [
            {
              activity_name: "Identity Documents",
              activity_code: "identity-documents",
              route: "/admin/jurisdiction/identitydocuments",
              id: 5,
            },
            
          ],
        },
        
        {
          activity_group_code: "technical",
          activity_group_name: "HR-Technical",
          menu: [
            {
              activity_name: "Menu",
              activity_code: "menu",
              route: "/admin/technical/menus",
              id: 5,
            },
            {
              activity_name: "Enums",
              activity_code: "enum",
              id: 5,
              route: "/admin/technical/enums",
            },
            {
              activity_name: "DB Model",
              activity_code: "Models",
              route: "/admin/technical/dbmodel",
              id: 5,
            },
          ],
        },
      ],
      hrmsMenu: [
        {
          activity_group_code: "hrms",
          activity_group_name: "HRMS",
          icon: "mdi-account",
          menu: [
            {
              activity_name: "Employee",
              activity_code: "employee",
              route: "/hrms/employee",
              id: 5,
            },
            {
              activity_name: "Self Service",
              activity_code: "selfservice",
              route: "/hrms/selfService",
              id: 5,
            },
            {
              activity_name: "Leave",
              activity_code: "Leave",
              id: 5,
            },
            {
              activity_name: "Attendance",
              activity_code: "attendance",
              id: 5,
            },
            {
              activity_name: "Performance",
              activity_code: "performance",
              id: 5,
            },
            {
              activity_name: "Travel",
              activity_code: "travel",
              id: 5,
            },
            {
              activity_name: "Expenses",
              activity_code: "expense",
              id: 5,
            },
            {
              activity_name: "Announcements",
              activity_code: "announcements",
              id: 5,
            },
          ],
        },
      ],
      erecruitMenu: [
        {
          activity_group_code: "recruit",
          activity_group_name: "E-Recruit",
          icon: "mdi-account-search",
          menu: [
            {
              activity_name: "Budget",
              activity_code: "budget",
              id: 5,
            },
            {
              activity_name: "Staffing Request",
              activity_code: "request",
              id: 5,
            },
            {
              activity_name: "Resume",
              activity_code: "resume",
              id: 5,
            },
            {
              activity_name: "Job Board",
              activity_code: "board",
              id: 5,
            },
            {
              activity_name: "Offer",
              activity_code: "offers",
              id: 5,
            },
            {
              activity_name: "Onboarding",
              activity_code: "onboarding",
              id: 5,
            },
          ],
        },
      ],
      organisation: [
        {
          activity_group_code: "Organisation",
          activity_group_name: "Organisation",
          icon: "mdi-account-group",
          menu: [
            {
              activity_name: "Company",
              activity_code: "company",
              route: "/admin/organisation/company",
              id: 5,
            },
            {
              activity_name: "Division",
              activity_code: "division",
              route: "/admin/organisation/division",
              id: 5,
            },
            {
              activity_name: "Department",
              activity_code: "department",
              route: "/admin/organisation/department",
              id: 5,
            },
            {
              activity_name: "Sub-Department",
              activity_code: "Sub-Department",
              route: "/admin/organisation/subdepartment",
              id: 5,
            },
            {
              "activity_name":"LOB",
              "activity_code":"LOB",
              "route":"/admin/organisation/lob",
              "id":5
            },
            {
              activity_name: "SBU",
              activity_code: "SBU",
              route: "/admin/organisation/sbu",
              id: 5,
            },
            {
              activity_name: "Branches",
              activity_code: "branches",
              route: "/admin/organisation/branches",
              id: 5,
            }
          ],
        },
      ],
      settingsMenu: [
        {
          activity_group_code: "settings",
          activity_group_name: "HR-Settings",
          icon: "mdi-cog",
          menu: [
            {
              activity_name: "Grade",
              activity_code: "Grade",
              id: 5,
              route: "/admin/settings/grade",
            },
            {
              activity_name: "Job Role",
              activity_code: "jobrole",
              id: 5,
              route: "/admin/settings/jobRole",
            },
            {
              activity_name: "Business Title",
              activity_code: "business-title",
              id: 5,
              route: "/admin/settings/BusinessTitle",
            },
            {
              activity_name: "Benefits",
              activity_code: "Benefit",
              id: 5,
              route: "/admin/settings/benefits",
            },
            {
              activity_name: " Benefits Template",
              activity_code: " BenefitTemplate",
              id: 5,
              route: "/admin/settings/BenefitTemplates",
            },
            {
              activity_name: " Leave",
              activity_code: " leave",
              id: 5,
              route: "/admin/settings/leave",
            },
            {
              activity_name: " Leave Template",
              activity_code: " leaveTemplate",
              id: 5,
              route: "/admin/settings/leaveTemplate",
            },
            {
              activity_name: " Holiday",
              activity_code: " holidays",
              id: 5,
              route: "/admin/settings/holidays",
            },
            {
              activity_name: " Holiday Template",
              activity_code: " holidaystemplates",
              id: 5,
              route: "/admin/settings/holidaysTemplates",
            },
            {
              activity_name: "Pay Component",
              activity_code: "component",
              id: 5,
              route: "/admin/settings/paycomponent",
            },
            {
              activity_name: "Pay Component Template",
              activity_code: "component",
              id: 5,
              route: "/admin/settings/PayComponentsTemplate",
            },
            {
              activity_name: "Work Timings",
              activity_code: "worktimings",
              id: 5,
              route: "/admin/settings/WorkTimings",
            },
          ],
        },
        {
          activity_group_code: "Identity",
          activity_group_name: "HR-Identity",
          menu: [
            {
              activity_name: "Role",
              activity_code: "role",
              route: "/admin/identity/role",
              id: 5,
            },
            {
              activity_name: "Security Group",
              activity_code: "security-group",
              route: "/admin/identity/securitygroup",
              id: 5,
            },
            {
              activity_name: "Permissions",
              activity_code: "permission",
              route: "/admin/identity/permission-config",
              id: 5,
            },
            {
              activity_name: "Assign Permissions",
              activity_code: "assign-permission",
              route: "/admin/identity/rolepermission",
              id: 5,
            },
            {
              activity_name: "User",
              activity_code: "user",
              route: "/admin/identity/user",
              id: 5,
            },
          ],
        },
        {
          activity_group_code: "technical",
          activity_group_name: "HR-Technical",
          menu: [
            {
              activity_name: "Menu",
              activity_code: "menu",
              route: "/admin/technical/menus",
              id: 5,
            },
            {
              activity_name: "Enums",
              activity_code: "enum",
              id: 5,
              route: "/admin/technical/enums",
            },
            {
              activity_name: "DB Model",
              activity_code: "Models",
              route: "/admin/technical/dbmodel",
              id: 5,
            },
          ],
        },
      ],
    };
  },
  created() {
    this.logCurrentRoutePath();
  },
  computed: {
    activityGroup() {
      return this.$store.getters.getSideMenuGroup;
    },
  },
  watch: {
    filteredMenu: function (newValue, oldValue) {
      // This function will be called when selectedMenu changes
      console.log("selectedMenu changed from", oldValue, "to", newValue);
      // You can perform actions based on the new value here
      if (newValue === "HRMS") {
        this.menuItems = this.hrmsMenu;
      }
      if (newValue === "e-Recruit") {
        this.menuItems = this.erecruitMenu;
      }
      if (newValue === "Settings") {
        this.menuItems = this.settingsMenu;
      }
      if (newValue === "Organisation") {
        this.menuItems = this.organisation;
      }
    },
    activityGroup: {
      deep: true,
      handler(oldValue, newValue) {
        // this.getSideMenu()
        console.log("old", oldValue.activity_group_id);
        console.log("new", newValue.activity_group_id);

        if (
          oldValue.activity_group_id != newValue.activity_group_id ||
          oldValue.activity_group_id == null
        )
          //this.getSideMenu()
          console.log("here");
      },
    },
  },
  /*query() {
      if (this.query) { 
        // this.groupId = this.query.activity_group_name
        this.listTitle = this.query.activity_group_name
        this.menuItems = this.query.subMenu
        // this.getMenus(this.groupId);
      }
    }*/
  methods: {
    toggleRail() {
      this.rail = !this.rail;
      setTimeout(()=>{
        this.toggleTip=='Minimize' ? this.toggleTip='Expand' : this.toggleTip='Minimize'
      },1000)
    },
    logCurrentRoutePath() {
      console.log(this.$route.path);
    },
    async getSideMenu() {
      // this.menuItems = []
      if (this.activityGroup && this.activityGroup.activity_group_code) {
        let $res = await AppService.getMenulist({
          roleId: 101,
          menuType: "activity",
          groupId: this.activityGroup.activity_group_code,
        });
        $res = $res.message;
        this.$store.commit("setSideMenuGroup", {
          activity_group_code: $res.activity_group_code,
          activity_group_name: $res.activity_group_name,
          activity_group_id: $res.activity_group_id,
          icon: $res.icon,
        });
        console.log($res, "kkkkkkdd");
        this.menuItems = $res.menu;
      }
    },

    menuClick($item) {
      this.selected = $item.activity_name;
      this.$router.push($item.route);
    },
    close() {
      this.dialog = false;
    },
    activemenu(item) {
      this.rail = false;
      if (item.name == "Logout") {
        this.dialog = true;
      }
      if (item.name == "Settings") {
        this.showMenu = !this.showMenu;
      }
    },
  },
};
</script>
  
<style scoped>
.side-menu {
  background-color: #1b1a47;
  cursor:pointer;  
}
>>> .v-list-group__header__append-icon {
  display: none !important;
}
>>> .v-list-item--density-default.v-list-item--one-line {
  min-height: 37px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.main-list-Title {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.subTitjjle {
  color: #b5bfc7;
  font-size: 13px;
  /* padding: 10px 20px; */
  font-weight: 400;
  /* border-radius: 4px; */
  /* margin-bottom: 4px; */
  position:relative;
  right:-20px;
}

/* .subTitjjle:hover .dividerr{
    color: #edf5ff;
    caret-color: #edf5ff;
    background-color: #edf5ff;
  } */

.active {
  font-size: 14px;
  font-weight: 500;
  color: #0f62fe;
  background-color: #edf5ff;

  /* .dividerr{
    color: #edf5ff;
    caret-color: #edf5ff;
    background-color: #edf5ff;
  } */
}
</style>
  