import Dbmodel  from '../listmodel/index.vue'
import Menus from '../menus/index.vue'
import Enums from '../enum/index.vue'

export default [
    {
        path: 'technical',
        children: [
            {
                path: "dbmodel",
                component: Dbmodel,
            },
            {
                path: "menus",
                component: Menus,
            },
            {
                path: "enums",
                component: Enums,
            }
        ]
    }
]