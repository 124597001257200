<template>
    <!-- <SideMenu :logOff="logOff"></SideMenu> -->
    <v-app-bar v-if="whileLogOut" class="topmenu" :elevation="0" style="min-height:20px">
      
        <v-btn class="main-menu-link" @click="(this.$emit('topMenuButton','HRMS'))">
           HRMS
        </v-btn>
        <v-btn class="main-menu-link" @click="(this.$emit('topMenuButton','e-Recruit'))">
           e-Recruit
        </v-btn>
        <v-btn class="main-menu-link" @click="(this.$emit('topMenuButton','Organisation'))">
           Organisation
        </v-btn>
        <v-btn class="main-menu-link" @click="(this.$emit('topMenuButton','Settings'))">
           Settings
        </v-btn>
       
        <template v-slot:append>
            <GlobalSearch  />
            <button class="menuBtn mr-4" v-bind="props" variant="text" @click="toggleTheme">
                <v-icon size="18px">mdi-theme-light-dark</v-icon>
            </button >
            <button class="menuBtn mr-4" v-bind="props" variant="text" @click="toggle">
                <v-icon size="18px"> mdi-help-circle-outline</v-icon>
            </button >

            <span v-bind="props" style="margin-left: 10px;display: flex;">
                <span >
                    <div style="font-size: 14px;font-weight: 400;color: #1B1A47;;text-align: left;">{{ userName }}</div>
                <!--     <div style="font-size: 12px;font-weight: 400;color: #e7e9ed;">Sr. Manager</div> -->
                </span>
                <span>
      <div>
          <button style="position: relative;left: 10px;" id="menu-activator" variant="text">
                <v-icon>mdi-account-circle-outline</v-icon>
            </button >
        <v-menu class="save-filter" activator="#menu-activator">
      <v-list  class="v-list-item-title ">
        <v-list-item
        class="v-list-item--density-default.v-list-item--one-line "
        @click="toggle"
          v-for="(item, index) in items"
          :key="index"
          :value="index"
        
        >
          <v-list-item-title >{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
            </div>
                </span>
            </span>
    </template>
    </v-app-bar>
    
</template>
<script>
import GlobalSearch from '@/components/GlobalSearch.vue';
// import SideMenu from '..//Components/SideMenu.vue'
export default {
    components:{
        GlobalSearch,
        // SideMenu
    },
    data() {
        return {
            userName: localStorage.getItem("_CUSER_"),
            items: [
        { title: 'Logout' },
        ],
        whileLogOut: true,
        }
    },
    methods: {
        topMenuButton(){
            // this.$emit('topMenuButton',menu);
        },
         toggle() {
        localStorage.clear(); 
        this.whileLogOut = false;
        this.$router.push('/login')
    },
    }
}
</script>
<style scoped>
>>>v-app-bar.v-toolbar {
    min-height:20px !important;
}
>>>v-toolbar__content {
}
.main-menu-link {
    color:#1B1A47;
    font-size:12px;
    font-weight:700;
}
.topmenu {
    background: orange;
    height:64px;
    padding:0px;
    margin:0px;
    height:64px;
}
.menuBtn{
    height: 28px;
    width: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eaedf2;
}
</style>