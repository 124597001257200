<template>
    <v-layout :data-theme="darkandlight">
      <v-main class="dashbordbodycontent" style="overflow: hidden !important;">
        <SideMenu  v-if="$route.path != '/login'" :userName="userName" :query="SideMenuData" :filteredMenu="this.seletedMenu"></SideMenu>

        <TopMenu v-if="$route.path != '/verification'" @DarkAndLight="DarkAndLight" @topMenuButton="handlemenuselect($event)"/>
      
        <div class="pt-0 my-5 mr-3" >
          <RouterView  :key="$route.fullPath">
          </RouterView>
        </div>
      </v-main>
    </v-layout>
  </template>
  <script>
  import TopMenu from './Components/TopMenu.vue'
  import SideMenu from './Components/SideMenu.vue'
  export default {

    props: ["appTheme"],
    components: {
        TopMenu,
        SideMenu
    },
    computed: {
      getActivityGroup() {
        return this.$store.getters.getSideMenuGroup;
      },
      isLoaderRequired() {
        return this.$store.state.showLoader;
      },
    },
    data() {
      return {
        toggleRail: false,
        darkandlight: "light",
        userName: localStorage.getItem("_CUSER_"),
  
        drawer: true,
        items: [
          { title: "Home", icon: "mdi-home-city" },
          { title: "My Account", icon: "mdi-account" },
          { title: "Users", icon: "mdi-account-group-outline" },
        ],
        rail: true,
        seletedMenu: null
      };
    },
    created: function () {
   
    },
    methods: {
      handlemenuselect($event){
        console.log($event)
        this.seletedMenu = $event
      },
      DarkAndLight($value) {
        if ($value.title == "Dark And Light") {
          this.darkandlight = this.darkandlight ? "light" : "dark";
        }
      },
      menuClick($item) {
        console.log($item, "itemsssssssssss"); 
      }
    },
  };
  </script>
  
  <style scoped>
  >>>.v-overlay__content{
  top:104px !important
  }
  
  .margin-top-10px {
    margin-top: 10px;
  }
  
  .margin-top-0 {
    margin-top: 0;
  }
  >>>.v-navigation-drawer{
    margin-top: 55px;
    border: none;
    border-radius: 2px;
  }
  </style>
  