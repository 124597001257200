import { createRouter, createWebHistory } from 'vue-router'
import publicPages from './publicPages.js'
import Organization from '@/modules/Organization/routes/route'
import Identity from '@/modules/Identity/routes/route'
import Technical from '@/modules/Technical/routes/route'
import Misc from '@/modules/Miscellaneous/routes/route'
import Integration from '@/modules/Integration/routes/route.js'
import Settings from '@/modules/Settings/routes/route.js'
import Hrms from '@/modules/hrms/routes/route.js'
import Jurisdiction from '@/modules/Jurisdiction/routes/route.js'
const routes = [
  { path: '/login', component: () => import('../modules/authorize/Login.vue') },
  { path: '/autoLogin', component: () => import('../modules/authorize/AutoLogin.vue') },
  { path: '/logout', component: () => import('../modules/authorize/Logout.vue') },


  { path: '/', component: () => import('../Layout/Home.vue') },
  {
    path: "/admin",
    name: "Admin",
    children: [
      ...Organization,
      ...Identity,
      ...Technical,
      ...Misc,
      ...Integration,
      ...Settings,
      ...Jurisdiction
    ]
  },
  {
    path: "/hrms",
    name: "Hrms",
    children: [
      ...Hrms
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const $token = localStorage.getItem("DMS_TOKEN");
  if (publicPages.includes(to.path)) {
    return next();
  }
  if ($token) {
    return next();
  } else {
    return next("/login");
  }
});

export default router
