<template>
    <!-- <v-layout> -->
    <div class="py-2" style="background-color: #fff;display: flex;padding-top: 5px;justify-content: space-between;">
        <div style="display: flex;align-items: center;">

            <div v-if="showContext">
                <span style="color: #0F62FE;font-size: 13px;font-weight: 500;">{{ itemsPerPage > allData ? allData :
                    itemsPerPage }}</span>
                <span style="color: #121619;font-size: 13px;font-weight: 400;"> of {{ allData }} results are showing
                    here</span>
            </div>
        </div>


        <div style="display: flex;gap: 15px;">

            <div style="width: 250px;">
                <v-text-field v-if="searchReq" density="compact" variant="outlined" class="text-Field custom-placeholder"
                    id="myTextField" v-model="search" placeholder="Search in table" @input="$emit('searchTable', search)"
                    prepend-inner-icon="mdi-magnify">
                </v-text-field>
            </div>

            <div style="display: flex;gap: 20px">

                <button v-if="columnReq" class="otherButton pt-2 pb-3 px-2" @click="columnEditor">
                    Columns
                    <span v-if="columns && columns.length" class="column-count">
                        {{ columns ? columns.length.toString().padStart(2, '0') : '' }}
                    </span>
                    <v-icon :color="'#0f62fe'">mdi-menu-down</v-icon>
                    <!-- <v-menu activator="parent" location="bottom" :close-on-content-click="false">
                        <v-list style="background-color: #f4f5f9;width: fit-content;">
                            <div class="contains" :class="customClass(columns)">

                                <div v-for="(item, index) in columns" :key="index">
                                    <v-checkbox :model-value="getModel(item.column_required)" class="py-0" :label="item.label"
                                        color="primary" @click="checkSelection(item)"></v-checkbox>
                                </div>
                            </div>
                        </v-list>
                    </v-menu> -->
                </button>

                <v-navigation-drawer v-model="drawer" location="right" temporary :width="550"
                    style="background: white; position: fixed;box-shadow: none; width: fit-content ;overflow: auto;padding-bottom: 50px;"
                    class="side-menu">
                    <v-card flat="" style="overflow: auto !important;">
                        <v-card-title>Customize Columns</v-card-title>
                        <div class="contains" :class="customClass(columns)">
                            <v-checkbox class="py-0 all" :model-value="allOptionsSelected" label="Select All"
                                color="primary" @click="allSelected()"></v-checkbox>

                            <div v-for="(item, index) in columns" :key="index">
                                <v-checkbox :model-value="getModel(item.column_required)" class="py-0" :label="item.label"
                                    color="primary" @click="checkSelection(item)"></v-checkbox>
                            </div>
                        </div>
                    </v-card>

                </v-navigation-drawer>


                    <TableFilter :model="model" ref="childComp" @filterData="$emit('filterData' , $event)"/>

                <button v-if="sortReq" class="otherButton px-6 " style="padding: 10px 0px;">
                    <i class="pt-1 mr-2 tibura-sort"></i>
                    Sort
                </button>

                <button @click="openFilter" v-if="filterReq" class="otherButton " style="padding: 10px 12px;">
                    <i class="pt-1 mr-2 tibura-filter_outline"></i>
                    Filters
                </button>
            </div>
        </div>
    </div>
    <!-- </v-layout> -->
</template>

<script>
import TableFilter from './TableFilter.vue';
export default {
    components:{TableFilter},
    props: ['itemsPerPage', 'allData', "model",'title', 'columns', 'showContext', 'searchReq', 'sortReq', 'filterReq', 'columnReq'],
    data() {
        return {
            allOptionsSelected: true,
            search: '',
            drawer: false,
            Fdrawer: false,
        }
    },
    watch:{
        columns(){
            this.getColumnCount()
        }
    },
    methods: {
        getColumnCount(){
            this.allOptionsSelected = this.columns.every(a => a.column_required === 1);
        },
        allSelected() {
            this.allOptionsSelected = !this.allOptionsSelected
            this.$emit("allSelected", this.allOptionsSelected);
        },
        columnEditor() {
            this.drawer = !this.drawer;
        },

        openFilter(){
            this.$refs.childComp.openFilter()
        },
  
        getModel(data) {
            if (data === 1) {
                return true
            } else {
                return false
            }
        },
        customClass(data) {
            if (!data) {
                return '';
            } else if (data.length < 9) {
                return '';
            } else if (data.length < 17) {
                return 'lessThan';
            } else if (data.length < 25) {
                return 'lessThan';
            } else if (data.length < 33) {
                return 'moreThan';
            } else if (data.length < 41) {
                return 'moreThan';
            } else {
                return 'moreMoreThan';
            }
        },

        checkSelection($item) {
            this.allOptionsSelected=false
            this.$emit("editColumns", $item);
        },
    }

}
</script>

<style scoped>
.otherButton {
    display: flex;
    align-items: center;
    background-color: #F4F4F4;
    color: #121619;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    height: fit-content;
    text-align: center;
}

.column-count {
    margin-left: 4px;
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #E1E2E5;
    color: #0F62FE;
    font-size: 12px;
    font-weight: 600;
    padding: 2px 5px;
}

>>>.v-text-field .v-input__details {
    padding-inline-start: 16px;
    padding-inline-end: 16px;
    display: contents;
}

>>>.v-list-item--density-default.v-list-item--one-line {
    min-height: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.item:hover {
    font-size: 12px;
    color: #121619;
    background-color: rgba(15, 98, 254, 0.10);
}

.optionText {
    font-size: 12px;
    color: #121619;
    font-weight: 400;
}

.custom-placeholder::placeholder {
    color: #0F62FE;
}

>>>.v-input__details {
    display: contents;
}



>>>.v-checkbox .v-selection-control {
    min-height: 45px;
}

.contains {
    display: grid;
}

.all {
    grid-row: 1;
    grid-column: 1/3;
}

.lessThan {
    grid-template-columns: repeat(2, 1fr);
}

.moreThan {
    grid-template-columns: repeat(3, 1fr);
}

.moreMoreThan {
    grid-template-columns: repeat(4, 1fr);
}

>>>::-webkit-scrollbar {
    width: 10px;
    height: 6px;
}

>>>::-webkit-scrollbar-track {
    background: #e8f3f9;
}

>>>::-webkit-scrollbar-thumb {
    background: rgb(0, 0, 0, 0.12);
    border-radius: 8px;
}


::-webkit-scrollbar-thumb:hover {
    background: #e8f3f9;
}</style>