import ApiService from "../ApiService.js";

const getModule = () => {
  return "auth";
};
const findAll = async ($data) => {
  let $options = {
    module: getModule(),
    options: {
      endpoint: "/grades",
      method: "GET",
      data: $data,
    },
  };
  let $response = await ApiService.sendRequest($options);
  return $response.data;
};
const create = async ($data) => {
  let $options = {
    module: getModule(),
    options: {
      endpoint: "/grades",
      method: "POST",
      data: $data,
    },
  };
  let $response = await ApiService.sendRequest($options);
  return $response.data;
};

const update = async ($data) => {
  let $options = {
    module: getModule(),
    options: {
      endpoint: "/grades/"+$data.id,
      method: "POST",
      data: $data,
    },
  };
  let $response = await ApiService.sendRequest($options);
  return $response;
};
const findOne = async ($data) => {
  let $options = {
    module: getModule(),
    options: {
      endpoint: "/grades/"+$data.id,
      method: "GET",
      data: $data,
    },
  };
  let $response = await ApiService.sendRequest($options);
  return $response;
};
export default { findAll,findOne,create,update  };
