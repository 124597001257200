import Company  from '../companies/index.vue'
import Division from '../division/index.vue'
import Branch from '../branches/index.vue'
import Lob from '../lob/index.vue'
import Sbu from '../sbu/index.vue'
import Zone from '../zone/index.vue'
import Department from '../department/index.vue'
import Subdepartment from '../subdepartment/index.vue'
import Location from '../locations/index.vue'
export default [
    {
        path: 'organisation',
        children: [
            {
                path: "company",
                component: Company,
            },
            {
                path: "division",
                component: Division,
            },
            {
                path: "branches",
                component: Branch,
            },
            {
                path: "lob",
                component: Lob,
            },
            {
                path: "sbu",
                component: Sbu,
            },
            {
                path: "zone",
                component: Zone,
            },
            {
                path: "location",
                component: Location,
            },
            {
                path: "department",
                component: Department,
            },
            {
                path: "subdepartment",
                component: Subdepartment,
            },
        ]
    }
]