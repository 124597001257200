import IdentityDocuments from '../IdentityDocuments/index.vue'
export default [
    {
        path: 'jurisdiction',
        children: [
            {
                path: "identitydocuments",
                component: IdentityDocuments,
            }
            
        ]
    }
]