import Grade  from '../Grade/index.vue'
import JobRole from '../JobRole/index.vue'
import paycomponent from '../payComponent/index.vue'
import PayComponentsTemplate from '../PayComponentsTemplate/index.vue'
import WorkTimings from '../WorkTimings/index.vue'
import Policy from '../Policy/index.vue'
import SBURule from '../SBURule/index.vue'
import BusinessTitle from '../BusinessTitle/index.vue'
import benefits from '../Benefits/index.vue'
import benefittemplates from '../BenefitTemplates/index.vue'
import leaveTemplate from '../LeaveTemplates/Index.vue'
import Leave from '../Leave/Index.vue'
import Holidays from '../Holidays/Index.vue'
import HolidaysTemplates from '../HolidaysTemplates/Index.vue'
export default [
    {
        path: 'settings',
        children: [
            {
                path: "grade",
                component: Grade,
            } ,
            {
                path: "jobRole",
                component: JobRole,
            },
            {
                path: "paycomponent",
                component: paycomponent,
            },
            {
                path: "benefits",
                component: benefits,
            },
            {
                path: "BenefitTemplates",
                component: benefittemplates,
            },
            {
                path: "BusinessTitle",
                component: BusinessTitle,
            },
            {
                path: "PayComponentsTemplate",
                component: PayComponentsTemplate,
            },
            {
                path: "WorkTimings",
                component: WorkTimings,
            },
            {
                path: "Policy",
                component: Policy,
            },
            {
                path: "SBURule",
                component: SBURule,
            },
            {
                path: "leaveTemplate",
                component: leaveTemplate,
            } ,
            {
                path: "leave",
                component: Leave,
            },
            {
                path: "holidays",
                component: Holidays,
            },
            {
                path: "holidaysTemplates",
                component: HolidaysTemplates,
            }
        ]
    }
]